import React from 'react';
import PropTypes from 'prop-types';
import { Box, Text } from '../../system';

const AddressHeadline = props => (
    <Text as="h5" fontWeight="semiBold" fontSize={2} color="black" {...props} />
);

const AddressPart = props => (
    <Text as="p" fontSize={2} color="cl-dark-blue.2" {...props} />
);

export const Address = ({ headline, parts, ...rest }) => (
    <Box {...rest}>
        <AddressHeadline>{headline}</AddressHeadline>
        <Box marginTop={2}>
            {parts.map((part, index) => (
                <AddressPart key={index}>{part}</AddressPart>
            ))}
        </Box>
    </Box>
);

Address.propTypes = {
    headline: PropTypes.string,
    parts: PropTypes.array
};
