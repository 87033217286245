import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from '../get';
import { Label } from '../Label';
import { Required } from '../Required';
import { theme } from '../theme';
import { COMMON } from '../constants';

const TextInput = styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0;

    display: block;
    width: 100%;
    outline: none;

    height: ${get('space.7')}px;
    padding-left: ${get('space.3')}px;
    padding-right: ${get('space.3')}px;
    border: 1px solid ${get('colors.cl-dark-blue.4')};

    font-size: ${get('fontSizes.2')}px;
    font-weight: ${get('fontWeights.regular')};
    color: ${get('colors.cl-dark-blue.0')};
    caret-color: ${get('colors.clear-blue.0')};

    &::placeholder {
        font-size: ${get('fontSizes.2')}px;
        color: ${get('colors.cl-dark-blue.4')};
    }

    &:hover {
        border: 1px solid ${get('colors.cl-dark-blue.3')};
    }

    &:focus {
        border: 1px solid ${get('colors.bold-blueish.0')};
    }
`;

const LabeledTextInputComponent = props => {
    return (
        <Label className={props.className}>
            {props.label}
            <Required required={props.required} />
            <TextInput {...props} />
        </Label>
    );
};

LabeledTextInputComponent.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool
};

export const LabeledTextInput = styled(LabeledTextInputComponent)`
    ${COMMON};
`;

LabeledTextInput.defaultProps = {
    theme
};
