import axios from 'axios';

export const createLead = ({
    name,
    companyName,
    businessEmailAddress,
    phoneNumber,
    message
}) => {
    return axios.post('api/v1/lead', {
        name,
        companyName,
        businessEmailAddress,
        phoneNumber,
        message
    });
};
