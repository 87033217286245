import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-grid-system';
import { createLead } from '../../api/lead.api';
import {
    LabeledTextInput,
    Button,
    BUTTON_MODIFIERS,
    Box,
    LabeledTextArea
} from '../system';
import { SvgCheck } from '../../icons/Check';

const TextInput = ({ onChange, ...rest }) => (
    <LabeledTextInput
        onChange={event => onChange(event.target.value)}
        marginBottom={4}
        width="100%"
        {...rest}
    />
);

TextInput.propTypes = {
    onChange: PropTypes.func
};

const STATE = {
    ERROR: 'error',
    SUCCESS: 'success'
};

export const LeadForm = props => {
    const [state, setState] = useState(null);
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [businessEmailAddress, setBusinessEmailAddress] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');

    const onSubmit = event => {
        event.preventDefault();

        createLead({
            name,
            companyName,
            businessEmailAddress,
            message,
            phoneNumber: phoneNumber ? phoneNumber : null
        })
            .then(() => {
                setState(STATE.SUCCESS);

                setName('');
                setCompanyName('');
                setBusinessEmailAddress('');
                setPhoneNumber('');
                setMessage('');
            })
            .catch(() => {
                setState(STATE.ERROR);
            })
            .finally(() => {
                setTimeout(() => {
                    setState(null);
                }, 3000);
            });
    };

    const getButtonModifiers = () => {
        const buttonModifiers = [BUTTON_MODIFIERS.PRIMARY];

        switch (state) {
            case STATE.SUCCESS: {
                buttonModifiers.push(BUTTON_MODIFIERS.SUCCESS);
                break;
            }
            case STATE.ERROR: {
                buttonModifiers.push(BUTTON_MODIFIERS.ERROR);
                break;
            }
            default: {
                break;
            }
        }

        return buttonModifiers;
    };

    const getButtonText = () => {
        switch (state) {
            case STATE.ERROR:
                return 'An error occurred';
            case STATE.SUCCESS:
                return 'Sent';
            default:
                return 'Get in contact';
        }
    };

    return (
        <form onSubmit={onSubmit}>
            <Row>
                <Col sm={12} md={6}>
                    <TextInput
                        label="Name"
                        placeholder="Aubrey Graham"
                        value={name}
                        onChange={setName}
                        required
                        marginBottom={3}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <TextInput
                        label="Company Name"
                        placeholder="OVO"
                        value={companyName}
                        onChange={setCompanyName}
                        required
                        marginBottom={3}
                    />
                </Col>
            </Row>

            <Row>
                <Col sm={12} md={6}>
                    <TextInput
                        label="Business Email Address"
                        placeholder="aubrey.graham@example.com"
                        type="email"
                        value={businessEmailAddress}
                        onChange={setBusinessEmailAddress}
                        required
                        marginBottom={3}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <TextInput
                        label="Phone"
                        placeholder="Call me on my cell phone"
                        type="tel"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        marginBottom={3}
                    />
                </Col>
            </Row>

            <Row>
                <Col sm={12}>
                    <LabeledTextArea
                        label="Message"
                        placeholder="Message"
                        value={message}
                        onChange={event => setMessage(event.target.value)}
                    />
                </Col>
            </Row>

            <Box
                width="100%"
                marginTop={2}
                marginLeft="auto"
                marginRight="auto"
            >
                <Button
                    type="submit"
                    width="100%"
                    modifiers={getButtonModifiers()}
                    icon={state === STATE.SUCCESS ? SvgCheck : null}
                    iconPosition={state === STATE.SUCCESS ? 'left' : null}
                >
                    {getButtonText()}
                </Button>
            </Box>
        </form>
    );
};
