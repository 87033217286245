import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '../system';

export const IntroductionText = ({ children }) => (
    <Text fontSize={4} color="cl-dark-blue.0" marginTop={3} as="p">
        {children}
    </Text>
);

IntroductionText.propTypes = {
    children: PropTypes.node
};
