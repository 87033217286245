import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '../system';

export const BorderedBox = ({ className, left, right, children, ...rest }) => {
    return (
        <Box
            width={[1, 1, 1 / 3]}
            className={className}
            display="inline-block"
            borderRight={[null, null, left ? 'divider' : null]}
            borderLeft={[null, null, right ? 'divider' : null]}
            paddingX={6}
            {...rest}
        >
            {children}
        </Box>
    );
};

BorderedBox.propTypes = {
    className: PropTypes.string,
    left: PropTypes.bool,
    right: PropTypes.bool,
    children: PropTypes.node
};
