import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-grid-system';

import { Heading3 } from '../system';
import { BodyText2 } from '../common';

export const HeadingTextCombination = props => {
    return (
        <Row>
            <Col md={8} sm={12}>
                <Heading3>{props.headline}</Heading3>
                <BodyText2 marginTop={2}>{props.text}</BodyText2>
            </Col>
        </Row>
    );
};

HeadingTextCombination.propTypes = {
    headline: PropTypes.string,
    text: PropTypes.string
};
