import { graphql, useStaticQuery } from 'gatsby';

export const useTeamMemberListDataWithFluidImages = () => {
    const data = useStaticQuery(graphql`
        query TeamMemberProfilesWithFluidQuery {
            allTeamMembersJson {
                edges {
                    node {
                        firstName
                        lastName
                        role
                        id
                        linkedIn
                        pastRoleDescription
                        pastOrganisation
                        categories
                    }
                }
            }

            allFile(
                filter: {
                    extension: { eq: "jpg" }
                    relativeDirectory: { eq: "team" }
                }
            ) {
                edges {
                    node {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                        name
                    }
                }
            }
        }
    `);

    const map = new Map();

    data.allTeamMembersJson.edges.forEach(edge => {
        const teamMember = { ...edge.node };
        map.set(teamMember.id, teamMember);
    });

    data.allFile.edges.forEach(edge => {
        const mapEntry = map.get(edge.node.name);

        if (mapEntry) {
            mapEntry.fluid = edge.node.childImageSharp.fluid;
        }
    });

    return Array.from(map.values());
};
