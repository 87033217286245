import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { Box, Button, BUTTON_MODIFIERS, Flex, get } from '../system';
import ROUTES from '../../routes';
import { CloselinkLogo } from './CloselinkLogo';
import { RoutedNavigationLink } from './RoutedNavigationLink';
import { DropdownNavigationMenu } from './DropdownNavigationMenu';

const HeaderNavigation = styled.ul`
    margin: 0;
    display: flex;
    align-items: center;
`;

const HeaderNavigationItem = styled.li`
    display: inline-block;
    margin-right: ${get('space.7')}px;

    &:last-child {
        margin-right: 0;
    }
`;

const HeaderSubNavigationItem = styled.li`
    display: inline-block;
    margin-right: ${get('space.2')}px;

    &:last-child {
        margin-right: 0;
    }
`;

const HeaderSubNavigation = styled(HeaderNavigation)`
    float: right;
    justify-content: flex-end;
`;

export const DesktopNavigation = () => {
    const { t } = useTranslation();

    const mainItems = [
        {
            text: t('header.navigation.purchaser'),
            href: ROUTES.PURCHASER
        },
        {
            text: t('header.navigation.supplier'),
            href: ROUTES.SUPPLIER
        }
    ];

    const dropdownItems = [
        {
            text: t('header.navigation.about'),
            href: ROUTES.ABOUT
        },
        {
            text: t('header.navigation.career'),
            href: ROUTES.CAREER
        },
        {
            text: t('header.navigation.news'),
            href: ROUTES.NEWS
        }
    ];

    return (
        <Box
            as="header"
            position="fixed"
            top={0}
            zIndex={2}
            backgroundColor="white"
            width="100%"
            paddingY={4}
        >
            <Flex
                width="90%"
                maxWidth="1280px"
                margin="0 auto"
                justifyContent="space-between"
            >
                <Flex flexDirection="row">
                    <Flex alignItems="center">
                        <Link to={ROUTES.INDEX}>
                            <CloselinkLogo width={150} />
                        </Link>
                    </Flex>
                    <Flex as="ul" alignItems="center" marginLeft={10}>
                        {mainItems.map(item => (
                            <HeaderNavigationItem key={item.href}>
                                <RoutedNavigationLink to={item.href}>
                                    {item.text}
                                </RoutedNavigationLink>
                            </HeaderNavigationItem>
                        ))}
                        <DropdownNavigationMenu dropdownItems={dropdownItems} />
                    </Flex>
                </Flex>
                <HeaderSubNavigation>
                    <HeaderSubNavigationItem>
                        <Button
                            as="a"
                            modifiers={[BUTTON_MODIFIERS.TEXT]}
                            href={ROUTES.APP}
                            display="inline-block"
                        >
                            {t('header.navigation.login')}
                        </Button>
                    </HeaderSubNavigationItem>
                    <HeaderSubNavigationItem>
                        <Button
                            as={Link}
                            modifiers={[BUTTON_MODIFIERS.PRIMARY]}
                            to={ROUTES.CONTACT}
                            display="inline-block"
                        >
                            {t('header.navigation.contact')}
                        </Button>
                    </HeaderSubNavigationItem>
                </HeaderSubNavigation>
            </Flex>
        </Box>
    );
};
