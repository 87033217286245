import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { default as englishTranslations } from '../locales/en';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: englishTranslations
        }
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false
    }
});
