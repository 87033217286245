import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

import { Box, Heading5, Link, Text } from '../system';

export const SingleCard = props => {
    const renderSubHeadline = () => {
        if (!props.subHeadline) {
            return null;
        }

        return (
            <Text
                as="h5"
                fontWeight="normal"
                fontSize={2}
                color="cl-dark-blue.4"
                marginTop={2}
            >
                {props.subHeadline}
            </Text>
        );
    };

    const renderLink = () => {
        if (!props.href || !props.linkText) {
            return null;
        }

        return (
            <Box marginTop={6}>
                <Link as={GatsbyLink} to={props.href}>
                    {props.linkText}
                </Link>
            </Box>
        );
    };

    return (
        <React.Fragment>
            <Heading5>{props.headline}</Heading5>

            {renderSubHeadline()}

            <Text as="p" color="cl-dark-blue.3" fontSize={2} marginTop={3}>
                {props.text}
            </Text>

            {renderLink()}
        </React.Fragment>
    );
};

SingleCard.propTypes = {
    headline: PropTypes.node.isRequired,
    subHeadline: PropTypes.string,
    text: PropTypes.string.isRequired,
    href: PropTypes.string,
    linkText: PropTypes.string
};
