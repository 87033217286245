import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-grid-system';

import { Default, Mobile } from './ScreenSizes';
import { Heading1, Heading3, Text } from '../system';

const HeaderWrapper = styled.div`
    text-align: center;
`;

export const ContentHeader = props => {
    return (
        <HeaderWrapper>
            <Container>
                <Row>
                    <Col>
                        <Default>
                            <Heading1>{props.headline}</Heading1>
                        </Default>

                        <Mobile>
                            <Heading3>{props.headline}</Heading3>
                        </Mobile>

                        <Row>
                            <Col sm={8} offset={{ sm: 2 }}>
                                <Text
                                    as="h3"
                                    color="cl-dark-blue.0"
                                    fontSize={4}
                                    marginTop={4}
                                    fontWeight="regular"
                                >
                                    {props.subHeadline}
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </HeaderWrapper>
    );
};

ContentHeader.propTypes = {
    headline: PropTypes.string,
    subHeadline: PropTypes.node
};
