import React from 'react';
import PropTypes from 'prop-types';

import logo from '../../images/closelink-logo.png';

// display: block because otherwise surrounding link has a greater height than image
// which leads to the issue that the logo is not centered in the header.
// https://stackoverflow.com/a/21726899/3276759
// TODO: maybe there is a better way.

export const CloselinkLogo = ({ width = 200 }) => (
    <img
        alt="Closelink Logo"
        width={width}
        src={logo}
        style={{ display: 'block' }}
    />
);

CloselinkLogo.propTypes = {
    width: PropTypes.number
};
