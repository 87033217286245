import React from 'react';
import { Link } from 'gatsby';
import { NavigationLink } from '../system';

export const RoutedNavigationLink = props => (
    <NavigationLink
        as={Link}
        activeClassName="active"
        partiallyActive
        {...props}
    />
);
