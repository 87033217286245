import { graphql, useStaticQuery } from 'gatsby';

export const useTeamMemberListDataWithFixedImages = () => {
    const data = useStaticQuery(graphql`
        query TeamMemberProfilesQuery {
            allTeamMembersJson {
                edges {
                    node {
                        firstName
                        lastName
                        role
                        id
                        linkedIn
                        pastRoleDescription
                        pastOrganisation
                        categories
                        fixedPosition
                    }
                }
            }

            allFile(
                filter: {
                    extension: { eq: "jpg" }
                    relativeDirectory: { eq: "team" }
                }
            ) {
                edges {
                    node {
                        childImageSharp {
                            fixed(width: 128, height: 128) {
                                ...GatsbyImageSharpFixed
                            }
                        }
                        name
                    }
                }
            }
        }
    `);

    const map = new Map();

    data.allTeamMembersJson.edges.forEach(edge => {
        const teamMember = { ...edge.node };
        map.set(teamMember.id, teamMember);
    });

    data.allFile.edges.forEach(edge => {
        const mapEntry = map.get(edge.node.name);

        if (mapEntry) {
            mapEntry.fixed = edge.node.childImageSharp.fixed;
        }
    });

    return Array.from(map.values());
};
