import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useTeamMemberDataWithFixedImages } from './index';

export const TeamMemberFixedImage = props => {
    const { fixed } = useTeamMemberDataWithFixedImages(props.id);

    return <Img fixed={fixed} alt={props.fullName} title={props.fullName} />;
};

TeamMemberFixedImage.propTypes = {
    id: PropTypes.string.isRequired,
    fullName: PropTypes.string.isRequired
};
