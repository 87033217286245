import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export const ClientOnly = ({ children }) => {
    // https://egghead.io/lessons/react-avoiding-state-flickers-in-gatsby-applications
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    if (!hasMounted) {
        return null;
    }

    return children;
};

ClientOnly.propTypes = {
    children: PropTypes.node
};
