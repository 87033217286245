import styled from 'styled-components';
import { get } from '../get';
import { theme } from '../theme';
import { TYPOGRAPHY } from '../constants';

export const NavigationLink = styled.a`
    position: relative;
    color: ${get('colors.cl-dark-blue.0')};
    text-decoration: none;
    font-weight: ${get('fontWeights.regular')};
    transition: all 0.15s ease;

    &:hover,
    &.active {
        color: ${get('colors.bold-blueish.0')};
        transition: all 0.15s ease;
    }

    ${TYPOGRAPHY};
`;

NavigationLink.defaultProps = {
    fontSize: 3,
    theme
};
