import React from 'react';
import { Box } from '../system';

export const BackgroundSection = props => {
    return (
        <Box
            paddingY={{ xs: 10, md: 16 }}
            backgroundColor="bold-blueish.10"
            {...props}
        />
    );
};
