import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { useTeamMemberDataWithFluidImages } from './useTeamMemberDataWithFluidImages';

export const TeamMemberFluidImage = props => {
    const { name, fluid } = useTeamMemberDataWithFluidImages(props.id);

    return (
        <Img
            style={{ borderRadius: '100%' }}
            fluid={fluid}
            alt={name}
            title={name}
        />
    );
};

TeamMemberFluidImage.propTypes = {
    id: PropTypes.string
};
