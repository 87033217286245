import React from 'react';
import { DesktopNavigation } from './DesktopNavigation';
import { MobileNavigation } from './MobileNavigation/MobileNavigation';

import { Desktop, NonDesktop } from './ScreenSizes';

export const Header = () => {
    return (
        <React.Fragment>
            <Desktop>
                <DesktopNavigation />
            </Desktop>
            <NonDesktop>
                <MobileNavigation />
            </NonDesktop>
        </React.Fragment>
    );
};
