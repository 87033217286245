import PropTypes from 'prop-types';
import * as styledSystem from 'styled-system';
import systemPropTypes from '@styled-system/prop-types';

const whiteSpace = styledSystem.system({
    whiteSpace: {
        property: 'whiteSpace',
        cssProperty: 'whiteSpace'
    }
});

export const TYPOGRAPHY = styledSystem.compose(
    styledSystem.typography,
    whiteSpace
);

TYPOGRAPHY.propTypes = {
    ...systemPropTypes.typography,
    whiteSpace: PropTypes.oneOf([
        'normal',
        'nowrap',
        'pre-wrap',
        'pre',
        'pre-line'
    ])
};

export const COMMON = styledSystem.compose(
    styledSystem.space,
    styledSystem.color,
    styledSystem.display
);
COMMON.propTypes = {
    ...systemPropTypes.space,
    ...systemPropTypes.color
};
export const BORDER = styledSystem.compose(
    styledSystem.border,
    styledSystem.shadow
);
BORDER.propTypes = {
    ...systemPropTypes.border,
    ...systemPropTypes.shadow
};

export const LAYOUT = styledSystem.layout;
export const POSITION = styledSystem.position;
export const FLEX = styledSystem.flexbox;
export const GRID = styledSystem.grid;

TYPOGRAPHY.propTypes = systemPropTypes.typography;
LAYOUT.propTypes = systemPropTypes.layout;
POSITION.propTypes = systemPropTypes.position;
FLEX.propTypes = systemPropTypes.flexbox;
GRID.propTypes = systemPropTypes.grid;
