import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ROUTES from '../../../routes';
import { Box, FooterLink as SystemFooterLink, get, Text } from '../../system';
import { Address } from './Address';

const NavigationList = styled.ul`
    list-style: none;
    margin-top: ${get('space.2')}px;
    margin-bottom: ${get('space.4')}px;
`;

const NavigationListItem = styled.li`
    margin-bottom: ${get('space.2')}px;

    &:last-child {
        margin-bottom: 0;
    }
`;

const Headline = props => (
    <Text as="h5" fontWeight="semiBold" color="black" fontSize={2} {...props} />
);

const Link = props => (
    <SystemFooterLink as={GatsbyLink} to={ROUTES.PURCHASER} {...props} />
);

export const Footer = () => {
    const { t } = useTranslation();
    const { image } = useStaticQuery(graphql`
        query {
            image: file(relativePath: { eq: "start-header-left.png" }) {
                childImageSharp {
                    fluid(maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `);

    return (
        <Box
            backgroundColor="bold-blueish.10"
            paddingY={11}
            paddingX={6}
            marginTop={18}
            position="relative"
        >
            <Img
                style={{
                    position: 'absolute',
                    left: 0,
                    bottom: 'calc(100% - 36px)',
                    width: '400px',
                    maxWidth: '100%'
                }}
                alt=""
                position="absolute"
                left={0}
                bottom={0}
                fluid={image.childImageSharp.fluid}
            />
            <Container>
                <Row justify="center">
                    <Col md={6} lg={2} offset={{ lg: 1 }}>
                        <Headline marginTop={{ _: 3, lg: 0 }}>
                            {t('footer.closelink')}
                        </Headline>

                        <NavigationList>
                            <NavigationListItem>
                                <Link to={ROUTES.PURCHASER}>
                                    {t('footer.purchaser')}
                                </Link>
                            </NavigationListItem>
                            <NavigationListItem>
                                <Link to={ROUTES.SUPPLIER}>
                                    {t('footer.supplier')}
                                </Link>
                            </NavigationListItem>
                            <NavigationListItem>
                                <Link to={ROUTES.ABOUT}>
                                    {t('footer.about')}
                                </Link>
                            </NavigationListItem>
                        </NavigationList>
                    </Col>

                    <Col md={6} lg={2}>
                        <Headline marginTop={{ _: 3, lg: 0 }}>
                            {t('footer.company')}
                        </Headline>

                        <NavigationList>
                            <NavigationListItem>
                                <Link to={ROUTES.CONTACT}>
                                    {t('footer.contact')}
                                </Link>
                            </NavigationListItem>
                            <NavigationListItem>
                                <Link to={ROUTES.CAREER}>
                                    {t('footer.career')}
                                </Link>
                            </NavigationListItem>
                            <NavigationListItem>
                                <Link to={ROUTES.SECURITY}>
                                    {t('footer.security')}
                                </Link>
                            </NavigationListItem>
                            <NavigationListItem>
                                <Link to={ROUTES.NEWS}>{t('footer.news')}</Link>
                            </NavigationListItem>
                        </NavigationList>
                    </Col>

                    <Col md={6} lg={2}>
                        <Headline marginTop={{ _: 3, lg: 0 }}>
                            {t('footer.termsAndPolicies')}
                        </Headline>

                        <NavigationList>
                            <NavigationListItem>
                                <Link to={ROUTES.IMPRINT}>
                                    {t('footer.imprint')}
                                </Link>
                            </NavigationListItem>
                            <NavigationListItem>
                                <Link to={ROUTES.TERMS_AND_CONDITIONS}>
                                    {t('footer.termsAndConditions')}
                                </Link>
                            </NavigationListItem>
                            <NavigationListItem>
                                <Link to={ROUTES.PRIVACY_POLICY}>
                                    {t('footer.privacyPolicy')}
                                </Link>
                            </NavigationListItem>
                        </NavigationList>
                    </Col>

                    <Col sm={6} lg={3}>
                        <Address
                            marginTop={{ _: 3, lg: 0 }}
                            headline={t('footer.address.company')}
                            parts={t('footer.address.parts', {
                                returnObjects: true
                            })}
                        />
                    </Col>
                </Row>
            </Container>
        </Box>
    );
};
