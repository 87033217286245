import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
    space,
    color,
    shadow,
    typography,
    border,
    position,
    background
} from 'styled-system';
import systemPropTypes from '@styled-system/prop-types';
import { GRID, LAYOUT } from '../constants';
import { theme } from '../theme';

export const Box = styled.div`
    ${LAYOUT};
    ${GRID};
    ${border};
    ${space};
    ${color};
    ${typography};
    ${shadow};
    ${position};
    ${background};
`;

Box.defaultProps = { theme };

Box.propTypes = {
    ...LAYOUT.propTypes,
    ...GRID.propTypes,
    ...systemPropTypes.space,
    ...systemPropTypes.color,
    ...systemPropTypes.background,
    theme: PropTypes.object
};
