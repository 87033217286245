import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';

import { COMMON, LAYOUT } from '../constants';
import { theme } from '../theme';
import { get } from '../get';
import { ConditionalRenderer } from '../../common/ConditionalRenderer';

export const BUTTON_MODIFIERS = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    OUTLINED: 'outlined',
    TEXT: 'text',
    SUCCESS: 'success',
    ERROR: 'error'
};

const modifierConfig = {
    [BUTTON_MODIFIERS.PRIMARY]: props => css`
        color: white;
        background-color: ${get('colors.bold-blueish.0')(props)};

        &:hover:enabled {
            transform: translateY(-1px);
            box-shadow: ${get('shadows.ultraSmall')(props)};
            background-color: ${get('colors.bold-blueish.1')(props)};
        }

        &:disabled {
            background-color: ${get('colors.cl-dark-blue.4')(props)};
            color: ${get('colors.cl-dark-blue.3')(props)};
        }
        
        &:visited { 
            color: ${get('colors.bold-blueish.10')(props)}; 
        }
    `,
    [BUTTON_MODIFIERS.SECONDARY]: props => css`
        background-color: ${get('colors.bold-blueish.9')(props)};

        && {
            color: ${get('colors.bold-blueish.0')(props)};
        }

        &:hover:enabled {
            background-color: ${get('colors.bold-blueish.8')(props)};
        }

        &:disabled {
            color: ${get('colors.cl-dark-blue.3')(props)};
            background-color: ${get('colors.cl-dark-blue.9')(props)};
        }
    `,
    [BUTTON_MODIFIERS.OUTLINED]: props => css`
        border: 1px solid ${get('colors.bold-blueish.0')(props)};
        color: ${get('colors.bold-blueish.0')(props)};
        background-color: transparent;

        &:hover {
            border: 1px solid ${get('colors.bold-blueish.2')(props)};
        }

        &:disabled {
            color: ${get('colors.cl-dark-blue.3')(props)};
            border: ${get('colors.cl-dark-blue.4')(props)};
        }
    `,
    [BUTTON_MODIFIERS.TEXT]: props => css`
        font-size: 14px;
        font-weight: 600;
        background-color: transparent;

        && {
            color: ${get('colors.bold-blueish.0')(props)};
        }

        &:hover:enabled {
            background-color: ${get('colors.bold-blueish.9')(props)};
        }

        &:disabled {
            color: ${get('colors.cl-dark-blue.3')(props)};
        }
    `,
    [BUTTON_MODIFIERS.SUCCESS]: props => css`
        background-color: ${get('colors.confirming-green.0')(props)};

        &&:hover {
            background-color: ${get('colors.confirming-green.1')(props)};
            color: white;
        }
    `,
    [BUTTON_MODIFIERS.ERROR]: props => css`
        background-color: ${get('colors.signal-red.0')(props)};

        &&:hover {
            background-color: ${get('colors.signal-red.1')(props)};
            color: white;
        }
    `
};

export const StyledButton = styled.button`
    border: none;
    outline: none;
    height: 64px;
    line-height: 64px;
    padding-left: 40px;
    padding-right: 40px;
    font-weight: ${get('fontWeights.semiBold')};
    font-size: ${get('fontSizes.2')}px;
    transition: all 0.15s ease;

    text-decoration: none;
    text-align: center;

    &:hover {
        cursor: pointer;
        transition: all 0.15s ease;
    }

    &:disabled {
        cursor: not-allowed;
    }

    ${applyStyleModifiers(modifierConfig)};
`;

const TextWrapper = styled.span`
    color: inherit;
    vertical-align: middle;
`;

const Wrapper = styled.span`
    display: block;
`;

const ButtonComponent = props => {
    const { children, icon: Icon, iconPosition, ...rest } = props;

    // We filter marginTop to not have a React warning.
    // TODO: There might be a better solution than this.
    const { marginTop, ...buttonProps } = rest;

    const iconStyles = {
        verticalAlign: 'middle',
        top: '-1px',
        position: 'relative'
    };

    return (
        <StyledButton {...buttonProps}>
            <Wrapper>
                <ConditionalRenderer
                    condition={Icon && iconPosition === 'left'}
                >
                    {Icon && (
                        <Icon
                            style={{
                                ...iconStyles,
                                marginRight: '4px'
                            }}
                        />
                    )}
                </ConditionalRenderer>
                <TextWrapper>{children}</TextWrapper>
                <ConditionalRenderer
                    condition={Icon && iconPosition === 'right'}
                >
                    {Icon && (
                        <Icon
                            style={{
                                ...iconStyles,
                                marginLeft: '4px'
                            }}
                        />
                    )}
                </ConditionalRenderer>
            </Wrapper>
        </StyledButton>
    );
};

ButtonComponent.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.func,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    className: PropTypes.string,
    modifiers: PropTypes.array
};

const StyledSystemButton = styled(ButtonComponent)`
    ${COMMON};
    ${LAYOUT};
`;

export const Button = ({ as, ...rest }) => {
    return <StyledSystemButton forwardedAs={as} {...rest} />;
};

Button.propTypes = {
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    modifiers: PropTypes.array,
    ...COMMON.propTypes
};

Button.defaultProps = {
    className: '',
    theme
};
