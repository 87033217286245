import React from 'react';

export const CancelIcon = props => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            display="block"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5355 8.46446C15.145 8.07393 14.5118 8.07393 14.1213 8.46446L12 10.5858L9.87868 8.46446C9.48816 8.07393 8.85499 8.07393 8.46447 8.46446C8.07394 8.85498 8.07394 9.48815 8.46447 9.87867L10.5858 12L8.46447 14.1213C8.07394 14.5118 8.07394 15.145 8.46447 15.5355C8.85499 15.9261 9.48816 15.9261 9.87868 15.5355L12 13.4142L14.1213 15.5355C14.5118 15.9261 15.145 15.9261 15.5355 15.5355C15.9261 15.145 15.9261 14.5118 15.5355 14.1213L13.4142 12L15.5355 9.87867C15.9261 9.48815 15.9261 8.85498 15.5355 8.46446Z"
                fill="#193643"
            />
        </svg>
    );
};
