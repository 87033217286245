import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

import { CenteredTextWrapper } from './CenteredTextWrapper';
import { Flex, get, Heading3 } from '../system';
import { ExternalLink } from './ExternalLink';
import {
    SvgFacebook,
    SvgInstagram,
    SvgLinkedIn,
    SvgTwitter
} from '../../icons';

const StyledFlex = styled(Flex)`
    flex-direction: column;

    * {
        margin-bottom: ${get('space.3')}px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @supports (display: flex) {
        flex-direction: row;

        * {
            margin-bottom: 0;
        }
    }
`;

export const SocialMediaBox = () => {
    const { t } = useTranslation();

    return (
        <Row>
            <Col sm={12} md={8} offset={{ md: 2 }}>
                <Row>
                    <Col sm={8} md={10} offset={{ sm: 2, md: 1 }}>
                        <CenteredTextWrapper>
                            <Heading3>
                                {t('contact.socialMedia.headline')}
                            </Heading3>

                            <Row>
                                <Col xs={10} md={8} offset={{ xs: 1, md: 2 }}>
                                    <StyledFlex
                                        justifyContent="space-between"
                                        marginTop={5}
                                    >
                                        <ExternalLink href="https://www.linkedin.com/company/closelink/">
                                            <SvgLinkedIn height={30} />
                                        </ExternalLink>

                                        <ExternalLink href="https://twitter.com/closelink_com">
                                            <SvgTwitter height={30} />
                                        </ExternalLink>
                                        <ExternalLink href="http://instagram.com/closelink">
                                            <SvgInstagram height={30} />
                                        </ExternalLink>
                                        <ExternalLink href="https://www.facebook.com/closelinkcom">
                                            <SvgFacebook height={30} />
                                        </ExternalLink>
                                    </StyledFlex>
                                </Col>
                            </Row>
                        </CenteredTextWrapper>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
