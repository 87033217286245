import styled from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../theme';
import { TYPOGRAPHY, COMMON } from '../constants';

export const Text = styled.span`
    ${TYPOGRAPHY};
    ${COMMON};
`;

Text.defaultProps = {
    theme
};

Text.propTypes = {
    ...TYPOGRAPHY.propTypes,
    ...COMMON.propTypes,
    theme: PropTypes.object
};
