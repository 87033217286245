import styled from 'styled-components';
import { get } from '../get';
import { Link } from 'gatsby';

export const FooterLink = styled(Link)`
    font-size: ${get('fontSizes.2')}px;
    text-decoration: none;
    font-weight: ${get('fontWeights.regular')};
    transition: all 0.15s ease;

    &,
    &:visited {
        color: ${get('colors.cl-dark-blue.0')};
    }

    &:hover {
        color: ${get('colors.bold-blueish.0')};
    }
`;
