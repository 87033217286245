/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

import shareImageUrl from './share-image.png';

export function SEO({
    description,
    meta,
    title,
    pathname = '/',
    lang = 'en',
    postInformation,
    jobPostingInformation,
    featuredImage
}) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const url = postInformation?.slug
        ? `${site.siteMetadata.siteUrl}${postInformation.slug}`
        : `${site.siteMetadata.siteUrl}${pathname}`;

    const selectImage = () => {
        if (postInformation?.featuredImage) {
            return `${site.siteMetadata.siteUrl}${postInformation.featuredImage.src}`;
        }

        if (featuredImage) {
            return `${site.siteMetadata.siteUrl}${featuredImage}`;
        }

        return `${site.siteMetadata.siteUrl}${shareImageUrl}`;
    };

    const image = selectImage();

    const metaTags = [
        {
            name: 'image',
            content: image
        },
        {
            name: `description`,
            content: metaDescription
        },
        {
            property: `og:title`,
            content: title
        },
        {
            property: `og:description`,
            content: metaDescription
        },
        {
            property: `og:type`,
            content: `website`
        },
        {
            name: 'image',
            property: 'og:image',
            content: image
        },
        {
            property: 'og:url',
            content: url
        },
        {
            name: 'twitter:site',
            content: site.siteMetadata.author
        },
        {
            name: `twitter:title`,
            content: title
        },
        {
            name: `twitter:description`,
            content: metaDescription
        },
        {
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            name: 'twitter:image',
            content: image
        }
    ];

    if (postInformation) {
        metaTags.push({
            property: 'og:type',
            content: 'article'
        });
    }

    if (postInformation?.author?.twitterHandle) {
        metaTags.push({
            name: `twitter:creator`,
            content: postInformation?.author?.twitterHandle
        });
    }

    const renderChildren = () => {
        if (!jobPostingInformation) {
            return null;
        }

        // Instead of manually building the JSON (as was done before), which
        // needed escaping for \n and quotes, using JSON.stringify to build a
        // valid json.
        return (
            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org/',
                    '@type': 'JobPosting',
                    title: jobPostingInformation.title,
                    description: jobPostingInformation.description,
                    identifier: {
                        '@type': 'PropertyValue',
                        name: 'Closelink'
                    },
                    datePosted: jobPostingInformation.date,
                    employmentType: 'FULL_TIME',
                    hiringOrganization: {
                        '@type': 'Organization',
                        name: 'Closelink',
                        sameAs: 'https://www.closelink.com/',
                        logo: `${site.siteMetadata.siteUrl}/icons/icon-512x512.png`
                    },
                    jobLocation: {
                        '@type': 'Place',
                        address: {
                            '@type': 'PostalAddress',
                            streetAddress: 'Axel-Springer-Platz 3',
                            addressLocality: 'Hamburg',
                            addressRegion: 'Hamburg',
                            postalCode: '22576',
                            addressCountry: 'DE'
                        }
                    },
                    applicantLocationRequirements: {
                        '@type': 'Country',
                        name: 'Germany'
                    },
                    jobLocationType: 'TELECOMMUTE'
                })}
            </script>
        );
    };

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={metaTags.concat(meta)}
        >
            <script>
                {`
                    !function(){var i,e,t,s=window.legal=window.legal||[];if(s.SNIPPET_VERSION="3.0.0",i="https://widgets.legalmonster.com/v1/legal.js",!s.__VERSION__)if(s.invoked)window.console&&console.info&&console.info("legal.js: The initialisation snippet is included more than once on this page, and does not need to be.");else{for(s.invoked=!0,s.methods=["cookieConsent","document","ensureConsent","handleWidget","signup","user"],s.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(t),s.push(e),s}},e=0;e<s.methods.length;e++)t=s.methods[e],s[t]=s.factory(t);s.load=function(e,t){var n,o=document.createElement("script");o.setAttribute("data-legalmonster","sven"),o.type="text/javascript",o.async=!0,o.src=i,(n=document.getElementsByTagName("script")[0]).parentNode.insertBefore(o,n),s.__project=e,s.__loadOptions=t||{}},s.widget=function(e){s.__project||s.load(e.widgetPublicKey),s.handleWidget(e)}}}();
                
                    legal.widget({
                        type: "cookie",
                        widgetPublicKey: "KykidNhi68sLoVuCVZzgua2h",
                    });
            `}
            </script>

            {renderChildren()}

            <script type="application/ld+json">
                {`
                {
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "${site.siteMetadata.siteUrl}",
                    "logo": "${site.siteMetadata.siteUrl}/icons/icon-512x512.png"
                }
                `}
            </script>
        </Helmet>
    );
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    postInformation: PropTypes.object,
    jobPostingInformation: PropTypes.object,
    pathname: PropTypes.string,
    featuredImage: PropTypes.string
};
