import React from 'react';

export const ListIcon = props => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 8C6 7.44772 6.44772 7 7 7H17C17.5523 7 18 7.44772 18 8C18 8.55228 17.5523 9 17 9H7C6.44772 9 6 8.55228 6 8ZM6 12C6 11.4477 6.44772 11 7 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H7C6.44772 13 6 12.5523 6 12ZM7 15C6.44772 15 6 15.4477 6 16C6 16.5523 6.44772 17 7 17H9C9.55228 17 10 16.5523 10 16C10 15.4477 9.55228 15 9 15H7Z"
                fill="#193643"
            />
        </svg>
    );
};
