import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Box, Link, Text } from '../system';

const Wrapper = styled.div`
    text-align: center;
`;

export const StoryHighlight = props => {
    const renderLink = () => {
        if (!props.href || !props.linkText) {
            return null;
        }

        return (
            <Box marginTop={6}>
                <Link href={props.href}>{props.linkText}</Link>
            </Box>
        );
    };

    return (
        <Box padding={{ _: 6, lg: 10 }} border="divider" height="100%">
            <Text fontSize={4} as="blockquote" lineHeight="default">
                {props.quote}
            </Text>

            {renderLink()}

            <Box width="70px" height="70px" marginTop={8} marginX="auto">
                {props.profileImage}
            </Box>

            <Wrapper>
                <Text
                    color="cl-dark-blue.0"
                    fontSize={2}
                    marginTop={4}
                    display="block"
                >
                    {props.testimonialName}
                </Text>

                <Text color="cl-dark-blue.2" fontSize={2} display="block">
                    {props.testimonialJobTitle}
                </Text>
            </Wrapper>
        </Box>
    );
};

StoryHighlight.propTypes = {
    quote: PropTypes.string,
    profileImage: PropTypes.node,
    testimonialName: PropTypes.string,
    testimonialJobTitle: PropTypes.string,
    href: PropTypes.string,
    linkText: PropTypes.string
};
