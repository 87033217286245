import React from 'react';
import { Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Image = styled.img`
    width: 100%;
    height: auto;
`;

export const TwoImages = props => {
    return (
        <Row>
            <Col md={6} sm={12}>
                <Image src={props.first} />
            </Col>

            <Col md={6} sm={12}>
                <Image src={props.second} />
            </Col>
        </Row>
    );
};

TwoImages.propTypes = {
    first: PropTypes.string,
    second: PropTypes.string
};
