import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { BodyText1 } from './Body/BodyText1';
import { Heading5 as OriginalHeading5, Text, Box, Heading4 } from '../system';
import { TextLink } from './TextLink';

// Components to be used in pages (such as GTC) and blog posts.

const BodyText = props => <BodyText1 marginY={3} {...props} />;
const Heading2 = props => <OriginalHeading5 as="h2" marginTop={9} {...props} />;
const ListItem = props => (
    <Text
        as="li"
        color="cl-dark-blue.0"
        marginLeft={6}
        marginY={2}
        {...props}
    />
);

const Article = styled.article`
    * {
        word-wrap: break-word;
    }
`;

const ThematicBreak = () => <Box height="86px" />;

export const WrittenTextContent = ({ content, children }) => {
    return (
        <MDXProvider
            components={{
                h2: Heading2,
                h4: Heading4,
                p: BodyText,
                li: ListItem,
                a: TextLink,
                hr: ThematicBreak
            }}
        >
            <Article>
                {children}
                <MDXRenderer>{content}</MDXRenderer>
            </Article>
        </MDXProvider>
    );
};

WrittenTextContent.propTypes = {
    content: PropTypes.string,
    children: PropTypes.node
};
