import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TYPOGRAPHY, COMMON } from '../constants';
import { get } from '../get';
import { theme } from '../theme';

const Heading = styled.h1`
    font-weight: ${get('fontWeights.regular')};
    font-size: ${get('fontSizes.5')}px;
    color: ${get('colors.cl-dark-blue.0')};
    line-height: ${get('lineHeights.ultraCondensed')};
    ${TYPOGRAPHY};
    ${COMMON};
`;

Heading.defaultProps = {
    theme
};

Heading.propTypes = {
    ...COMMON.propTypes,
    ...TYPOGRAPHY.propTypes,
    theme: PropTypes.object
};

export const Heading1 = props => (
    <Heading fontSize={12} as="h1" fontWeight="semiBold" {...props} />
);
export const Heading2 = props => (
    <Heading as="h2" fontSize={10} fontWeight="bold" {...props} />
);
export const Heading3 = props => (
    <Heading
        as="h3"
        fontSize={8}
        fontWeight="bold"
        lineHeight="condensed"
        {...props}
    />
);
export const Heading4 = props => (
    <Heading
        as="h4"
        fontSize={8}
        fontWeight="semiBold"
        lineHeight="condensed"
        {...props}
    />
);

export const Heading5 = props => (
    <Heading
        as="h5"
        fontSize={6}
        fontWeight="regular"
        lineHeight="condensed"
        {...props}
    />
);

export const Heading6 = styled(Heading)`
    font-size: ${get('fontSizes.2')}px;
    font-weight: ${get('fontWeights.semiBold')};
    color: ${get('colors.cl-dark-blue.2')};
    text-transform: uppercase;
`;
