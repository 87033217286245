import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { BodyText1 } from './index';
import { Box, Flex, Text } from '../system';
import { TeamMemberFluidImage } from './TeamMemberFluidImage';
import { useTeamMemberDataWithFluidImages } from './useTeamMemberDataWithFluidImages';

export const Quote = ({ text, author }) => {
    const { name, role } = useTeamMemberDataWithFluidImages(author);
    const { t } = useTranslation();

    return (
        <Box
            marginTop={4}
            paddingX={5}
            paddingY={5}
            backgroundColor="bold-blueish.9"
            borderRadius={16}
        >
            <BodyText1>{text}</BodyText1>

            <Flex alignItems="center" marginTop={2}>
                <Box width="30px" height="30px" display="inline-block">
                    <TeamMemberFluidImage id={author} />
                </Box>
                <Text marginLeft={2} color="cl-dark-blue.2">
                    {name}, {role} {t('common.quote.authorNameSuffix')}
                </Text>
            </Flex>
        </Box>
    );
};

Quote.propTypes = {
    text: PropTypes.string,
    author: PropTypes.string
};
