import React from 'react';
import logo from '../../images/small-closelink-logo.png';

export const PictorialCloselinkLogo = () => (
    <img
        alt="Closelink Logo"
        style={{ display: 'block' }}
        width={40}
        height={40}
        src={logo}
    />
);
