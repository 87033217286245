import React from 'react';
import PropTypes from 'prop-types';

import { Box, Flex, Heading3, Text } from '../system';
import { BodyText2 } from '../common';

export const BenefitContent = props => {
    return (
        <Flex height="100%" alignItems="center" justifyContent="center">
            <Box>
                <Heading3>{props.headline}</Heading3>

                <BodyText2 marginTop={3} marginBottom={4}>
                    {props.description}
                </BodyText2>

                <Box paddingLeft={3}>
                    <ul>
                        {props.bulletItems?.map((item, i) => (
                            <Text
                                as="li"
                                key={i}
                                fontWeight="semiBold"
                                marginBottom={3}
                            >
                                {item}
                            </Text>
                        ))}
                    </ul>
                </Box>
            </Box>
        </Flex>
    );
};

BenefitContent.propTypes = {
    headline: PropTypes.node,
    description: PropTypes.string,
    bulletItems: PropTypes.array
};
