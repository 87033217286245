import React from 'react';
export const SvgFrontArrow = props => (
    <svg width={24} height={24} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.879 8.467a1 1 0 10-1.415 1.414l1.12 1.12H7.001c-.55 0-1 .45-1 1s.45 1 1 1h7.586l-1.123 1.123a1 1 0 101.414 1.414l2.828-2.828a1 1 0 000-1.414L14.88 8.467z"
            fill="currentColor"
        />
    </svg>
);
