export default {
    INDEX: '/',
    SUPPLIER: '/supplier',
    PURCHASER: '/purchaser',
    ABOUT: '/about',
    NEWS: '/news',
    APP: '/app/login',
    CONTACT: '/contact',
    IMPRINT: '/imprint',
    TERMS_AND_CONDITIONS: '/terms-conditions',
    PRIVACY_POLICY: '/privacy-policy',
    SECURITY: '/security',
    FAQ: '/faq',
    CAREER: '/career',
    ENTER_THE_FUTURE: '/enter-the-future'
};
