import styled from 'styled-components';
import { get } from '../system';

export const TextLink = styled.a`
    color: ${get('colors.bold-blueish.0')};

    &:visited {
        color: ${get('colors.bold-blueish.0')};
    }

    &:hover {
        color: ${get('colors.cl-dark-blue.0')};
    }
`;
