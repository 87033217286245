import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { TYPOGRAPHY, COMMON } from '../constants';
import { theme } from '../theme';

const Base = props => {
    const { color, lineHeight, fontFamily, theme, ...rest } = props;
    return <div {...rest}>{props.children}</div>;
};

Base.propTypes = {
    color: PropTypes.string,
    lineHeight: PropTypes.string,
    fontFamily: PropTypes.string,
    theme: PropTypes.object,
    children: PropTypes.node
};

export const BaseStyle = styled(Base)`
    ${TYPOGRAPHY};
    ${COMMON};
`;

BaseStyle.defaultProps = {
    fontFamily: 'normal',
    lineHeight: 'default',
    theme
};

BaseStyle.propTypes = {
    ...TYPOGRAPHY.propTypes,
    ...COMMON.propTypes,
    theme: PropTypes.object
};
