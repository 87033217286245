const typography = {
    fontSizes: [
        10,
        12,
        14,
        16,
        18,
        20, // .5
        24,
        32,
        40,
        48,
        56, //.10
        64,
        72
    ],
    lineHeights: {
        ultraCondensed: 1.1,
        condensed: 1.25,
        default: 1.5,
        wide: 1.75
    },
    fontWeights: {
        light: 200,
        regular: 400,
        semiBold: 600,
        bold: 700
    }
};

const colors = {
    'cl-dark-blue': [
        '#193643',
        '#475E68',
        '#75878E',
        '#A3AFB4',
        '#D1D7D9',
        '#DCDFE1',
        '#EDEFF0',
        '#F1F3F4',
        '#F6F7F7',
        '#FAFBFB',
        '#FDFDFD'
    ],
    'bold-blueish': [
        '#0001FE',
        '#3334FE',
        '#6667FE',
        '#9999FF',
        '#CCCCFF',
        '#E1E1FA',
        '#E6E6FA',
        '#EBEBFA',
        '#F0F0FA',
        '#F5F5FA',
        '#F8F8FA'
    ],
    'mellow-teal': [
        '#1DE9B6',
        '#4AEDC5',
        '#77F2D3',
        '#A5F6E2',
        '#D2FBF0',
        '#E4F8F3',
        '#E8F9F5',
        '#EDF9F6',
        '#F1F9F7',
        '#F6FAF9',
        '#F8FAF9'
    ],
    'vibrant-aqua': [
        '#00FFF2',
        '#33FFF5',
        '#66FFF7',
        '#99FFFA',
        '#CCFFFC',
        '#E1FBF9',
        '#E6FAF9',
        '#EBFAFA',
        '#F0FAFA',
        '#F5FAFA',
        '#F8FAFA'
    ],
    'striking-purple': [
        '#6700FE',
        '#8633FE',
        '#A466FE',
        '#C299FF',
        '#E1CCFF',
        '#EBE1FA',
        '#EEE6FA',
        '#F1EBFA',
        '#F4F0FA',
        '#F7F5FA',
        '#F9F8FA'
    ],
    'clear-blue': [
        '#0068FF',
        '#3386FF',
        '#66A4FF',
        '#99C3FF',
        '#CCE1FF',
        '#E1EBFB',
        '#E6EEFA',
        '#EBF1FA',
        '#F0F4FA',
        '#F5F7FA',
        '#F8F9FA'
    ],
    'shining-yellow': [
        '#FFE200',
        '#FFE833',
        '#FFEE66',
        '#FFF499',
        '#FFF9CC',
        '#FBF8E1',
        '#FAF8E6',
        '#FAF9EB',
        '#FAF9F0',
        '#FAFAF5',
        '#FAFAF8'
    ],
    'signal-red': [
        '#FE355A',
        '#FE5D7B',
        '#FE869C',
        '#FFAEBD',
        '#FFD7DE',
        '#FAE6EA',
        '#FAEAED',
        '#FAEEF0',
        '#FAF2F4',
        '#FAF6F7',
        '#FAF8F8'
    ],
    'confirming-green': [
        '#00CC69',
        '#32D586',
        '#64DFA3',
        '#96E8C0',
        '#C8F1DD',
        '#E1F5EB',
        '#E6F6EE',
        '#EBF7F1',
        '#F0F8F4',
        '#F5F9F7',
        '#F8FAF9'
    ]
};

const breakpointsUnitLess = {
    sm: 544,
    md: 768,
    lg: 1012,
    xlg: 1280
};

const breakpoints = Object.values(breakpointsUnitLess).map(
    breakpoint => `${breakpoint}px`
);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xlg = breakpoints[3];

const theme = {
    breakpoints,
    space: [
        0,
        4,
        8,
        16,
        24,
        32, // .5
        40,
        48,
        56,
        64,
        72, // .10
        80,
        96,
        104,
        120,
        144, // .15
        160,
        200,
        240
    ],
    colors,
    fontSizes: typography.fontSizes,
    lineHeights: typography.lineHeights,
    fontWeights: typography.fontWeights,
    fonts: {
        normal: fontStack(['NeutrifStudio', 'OpenSans', 'Helvetica', 'Arial'])
    },
    shadows: {
        ultraSmall: '0 4px 8px 0 rgba(25,54,67,0.1)',
        small: '0 8px 16px 0 rgba(25,54,67,0.1)',
        medium: '0 16px 24px 0 rgba(25,54,67,0.1)',
        large: '0 24px 32px 0 rgba(25,54,67,0.1)'
    },
    borders: {
        divider: `1px solid ${colors['cl-dark-blue'][6]}`
    }
};

function fontStack(fonts) {
    return fonts
        .map(font => (font.includes(' ') ? `"${font}"` : font))
        .join(', ');
}

export { theme, breakpointsUnitLess };
