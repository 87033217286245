import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

import { Box, Heading3 } from '../system';
import { LeadForm } from './LeadForm';
import { LeadFormSubHeadline } from './LeadFormSubHeadline';

export const ProminentLeadForm = props => {
    const { t } = useTranslation();

    return (
        <Container>
            <Row>
                <Col>
                    <Box paddingY={13} backgroundColor="bold-blueish.9">
                        <Row>
                            <Col xs={10} md={8} offset={{ md: 2, xs: 1 }}>
                                <Heading3>
                                    {t('common.contactForm.headline')}
                                </Heading3>

                                <LeadFormSubHeadline
                                    imagePair={props.imagePair}
                                    subHeadline={t(
                                        'common.contactForm.subHeadline'
                                    )}
                                    showAvatars={props.showAvatars}
                                />
                                <Box marginTop={10}>
                                    <LeadForm />
                                </Box>
                            </Col>
                        </Row>
                    </Box>
                </Col>
            </Row>
        </Container>
    );
};

ProminentLeadForm.propTypes = {
    imagePair: PropTypes.shape({
        firstUserId: PropTypes.string,
        secondUserId: PropTypes.string
    }),
    showAvatars: PropTypes.bool
};

ProminentLeadForm.defaultProps = {
    showAvatars: true
};
