import { useMediaQuery } from 'react-responsive';
import { breakpointsUnitLess } from '../system/theme';

export const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: breakpointsUnitLess.lg });
    return isDesktop ? children : null;
};

export const NonDesktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: breakpointsUnitLess.lg });
    return !isDesktop ? children : null;
};

export const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({
        minWidth: breakpointsUnitLess.md,
        maxWidth: breakpointsUnitLess.lg - 1
    });
    return isTablet ? children : null;
};

export const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: breakpointsUnitLess.md - 1 });
    return isMobile ? children : null;
};

export const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ minWidth: breakpointsUnitLess.md });
    return isNotMobile ? children : null;
};
