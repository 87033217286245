import { useTeamMemberListDataWithFluidImages } from './useTeamMemberListDataWithFluidImages';

// Quick workaround since you can't pass variables to a GraphQL query.
// https://github.com/gatsbyjs/gatsby/issues/10482

export const useTeamMemberDataWithFluidImages = id => {
    const profiles = useTeamMemberListDataWithFluidImages();

    return profiles.find(profile => profile.id === id);
};
