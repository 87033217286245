import PropTypes from 'prop-types';
import React from 'react';
import { Box, Flex } from '../system';
import { BodyText2 } from './Body/BodyText2';
import { Default } from './ScreenSizes';
import { TeamMemberFluidImage } from './TeamMemberFluidImage';

const ImagePairBox = props => (
    <Box width="40px" height="auto" display="inline-block" {...props} />
);

const ImagePair = props => {
    return (
        <React.Fragment>
            <ImagePairBox>
                <TeamMemberFluidImage id={props.firstUserId} />
            </ImagePairBox>
            <ImagePairBox marginLeft={-3}>
                <TeamMemberFluidImage id={props.secondUserId} />
            </ImagePairBox>
        </React.Fragment>
    );
};

ImagePair.propTypes = {
    firstUserId: PropTypes.node,
    secondUserId: PropTypes.node
};

export const LeadFormSubHeadline = props => {
    if (!props.showAvatars) {
        return <BodyText2 marginTop={4}>{props.subHeadline}</BodyText2>;
    }

    return (
        <Flex alignItems="center" marginTop={4}>
            <Default>
                <Flex marginRight={3}>
                    <ImagePair
                        firstUserId={props.imagePair.firstUserId}
                        secondUserId={props.imagePair.secondUserId}
                    />
                </Flex>
            </Default>

            <BodyText2>{props.subHeadline}</BodyText2>
        </Flex>
    );
};

LeadFormSubHeadline.propTypes = {
    showAvatars: PropTypes.bool,
    imagePair: PropTypes.object,
    subHeadline: PropTypes.string
};
