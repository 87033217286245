import './src/components/global.css';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

export const onClientEntry = () => {
    // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
    if (typeof window.IntersectionObserver === `undefined`) {
        // eslint-disable-next-line no-unused-expressions
        import(`intersection-observer`);
        console.log(`# IntersectionObserver is polyfilled!`);
    }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    //Track route changes with Leadfeeder
    try {
        if (
            window.ldfdr &&
            window.ldfdr.pageview &&
            window.legal &&
            window.legal.getCookieConsentState().marketing
        ) {
            window.ldfdr.pageview();
        }

        //Track route changes with LinkedIn
        if (
            window.lintrk &&
            window.legal &&
            window.legal.getCookieConsentState().marketing
        ) {
            window.lintrk('track');
        }

        if (prevLocation != null && window.dataLayer) {
            window.dataLayer.push({ event: 'Route Changed' });
        }
    } catch (error) {
        console.error(error);
    }
};
