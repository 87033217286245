import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { constants, theme } from '../../system';

const IllustrationComponent = ({ name, className = '' }) => {
    const data = useStaticQuery(
        graphql`
            {
                allFile(
                    filter: { relativeDirectory: { eq: "illustrations" } }
                ) {
                    edges {
                        node {
                            childImageSharp {
                                fluid(maxWidth: 120) {
                                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                                }
                            }
                            name
                        }
                    }
                }
            }
        `
    );

    const illustration = data.allFile.edges.find(edge => {
        return edge.node.name === name;
    });

    return (
        <Img
            className={className}
            fluid={illustration.node.childImageSharp.fluid}
            alt=""
        />
    );
};

IllustrationComponent.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string
};

const StyledIllustrationComponent = styled(IllustrationComponent)`
    ${constants.LAYOUT};
`;

StyledIllustrationComponent.defaultProps = {
    theme
};

export const IconIllustration = props => (
    <StyledIllustrationComponent
        width={{ xs: '80px', md: '120px' }}
        name={props.name}
    />
);

IconIllustration.propTypes = {
    name: PropTypes.string
};
