import React from 'react';
import PropTypes from 'prop-types';
import { setConfiguration } from 'react-grid-system';

import { BaseStyle, Box } from '../system';
import '../../i18n';
import { breakpointsUnitLess } from '../system/theme';
import { Footer } from './Footer/Footer';
import { Header } from './Header';

setConfiguration({
    breakpoints: Object.values(breakpointsUnitLess)
});

export const Layout = ({ children }) => {
    return (
        <BaseStyle>
            <Header />
            <Box marginTop={{ xs: 15, lg: 18 }}>{children}</Box>
            <Footer />
        </BaseStyle>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};
