import { graphql, Link, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
import ROUTES from '../../routes';
import { ArticlePreview, CenteredTextWrapper, SpacedCol } from '../common';
import { Box, Button, BUTTON_MODIFIERS, Heading3 } from '../system';

const useArticles = (tag, limit = 3) => {
    const data = useStaticQuery(graphql`
        {
            allMdx(
                sort: { order: DESC, fields: frontmatter___date }
                filter: { frontmatter: { tags: { ne: null } } }
            ) {
                edges {
                    node {
                        fields {
                            slug
                        }
                        frontmatter {
                            date(formatString: "DD.MM.YYYY")
                            title
                            author
                            excerpt
                            tags
                        }
                    }
                }
            }
        }
    `);

    const latestPosts = data.allMdx.edges
        .filter(edge => {
            // Do not filter if there is no tag.
            if (!tag) {
                return true;
            }
            return edge.node.frontmatter.tags.includes(tag);
        })
        .slice(0, limit);

    return latestPosts.map(({ node }) => {
        return {
            title: node.frontmatter.title,
            date: node.frontmatter.date,
            author: node.frontmatter.author,
            excerpt: node.frontmatter.excerpt,
            slug: node.fields.slug
        };
    });
};

const colProps = postsPerRow => {
    switch (postsPerRow) {
        case 3:
            return {
                xs: 12,
                lg: 4
            };
        default:
            return {
                xs: 12,
                md: 8,
                offset: { xs: 0, md: 2 }
            };
    }
};

export const FeaturedPosts = ({ tag, postsPerRow }) => {
    const { t } = useTranslation();
    const articles = useArticles(tag);

    const result = (
        <React.Fragment>
            <Heading3 marginBottom={6} textAlign="center">
                {t('common.news.headline')}
            </Heading3>

            <Box marginBottom={8}>
                <Container>
                    <Row>
                        {articles.map(article => (
                            <SpacedCol
                                marginTop={5}
                                key={article.slug}
                                {...colProps(postsPerRow)}
                            >
                                <ArticlePreview article={article} />
                            </SpacedCol>
                        ))}
                    </Row>
                </Container>
            </Box>

            <CenteredTextWrapper>
                <Button
                    as={Link}
                    modifiers={[BUTTON_MODIFIERS.SECONDARY]}
                    to={ROUTES.NEWS}
                    display="inline-block"
                >
                    {t('common.news.newsOverviewLinkText')}
                </Button>
            </CenteredTextWrapper>
        </React.Fragment>
    );

    return result;
};

FeaturedPosts.propTypes = {
    tag: PropTypes.string,
    postsPerRow: PropTypes.number
};
