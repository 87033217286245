import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-grid-system';
import { theme, constants } from '../system';

const { COMMON } = constants;

export const SpacedCol = styled(
    ({ marginY, marginTop, marginBottom, ...rest }) => <Col {...rest} />
)`
    ${COMMON};
`;

SpacedCol.defaultProps = {
    theme
};
