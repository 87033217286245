import { Link as GatsbyLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Heading5, Link, Text } from '../system';
import { TeamMemberFluidImage } from './TeamMemberFluidImage';
import { useTeamMemberListDataWithFluidImages } from './useTeamMemberListDataWithFluidImages';

export const ArticlePreview = ({ article }) => {
    const { t } = useTranslation();
    const profiles = useTeamMemberListDataWithFluidImages();

    const authorProfile = profiles.find(
        profile => profile.id === article.author
    );

    return (
        <GatsbyLink to={article.slug}>
            <Box border="divider" padding={6} height="100%">
                <Flex alignItems="center">
                    <Box width="30px" height="30px" display="inline-block">
                        <TeamMemberFluidImage id={article.author} />
                    </Box>
                    <Text
                        display="inline-block"
                        as="h5"
                        fontWeight="normal"
                        fontSize={2}
                        color="cl-dark-blue.2"
                        marginLeft={2}
                    >
                        {`${authorProfile.firstName} ${authorProfile.lastName}`}
                    </Text>
                </Flex>

                <Heading5 marginTop={4}>{article.title}</Heading5>

                <Text as="p" color="cl-dark-blue.1" fontSize={2} marginTop={2}>
                    {article.excerpt}
                </Text>

                <Box marginTop={6}>
                    <Link as="span">{t('common.news.newsPostLinkText')}</Link>
                </Box>
            </Box>
        </GatsbyLink>
    );
};

ArticlePreview.propTypes = {
    article: PropTypes.shape({
        slug: PropTypes.string,
        excerpt: PropTypes.string,
        title: PropTypes.string,
        author: PropTypes.string
    })
};
