import React from 'react';
export const SvgCheck = props => (
    <svg width={24} height={24} fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.035 8.536a1 1 0 00-1.414 0l-4.95 4.95-2.12-2.122a1 1 0 10-1.415 1.414l2.828 2.829a1 1 0 001.415 0l5.656-5.657a1 1 0 000-1.414z"
            fill="#fff"
        />
    </svg>
);
