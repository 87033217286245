import { Quote } from "../../../../src/components/common/Quote";
import { TwoImages } from "../../../../src/components/news/TwoImages";
import { IntroductionText } from "../../../../src/components/news/IntroductionText";
import { IllustrationProcessVideo } from "../../../../content/news/website-case-study/IllustrationProcessVideo";
import illustrationMeasurementUrl from "../../../../content/news/website-case-study/illustration-measurement.jpg";
import illustrationStrengthUrl from "../../../../content/news/website-case-study/illustration-strength.jpg";
import * as React from 'react';
export default {
  Quote,
  TwoImages,
  IntroductionText,
  IllustrationProcessVideo,
  illustrationMeasurementUrl,
  illustrationStrengthUrl,
  React
};