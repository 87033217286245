import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImage from 'gatsby-background-image';

export const IllustrationBackgroundPair = ({
    className,
    imageStack,
    children
}) => {
    return (
        <BackgroundImage
            critical
            Tag="div"
            className={className}
            fluid={imageStack}
            style={{
                height: '100%',
                backgroundSize: '400px auto',
                backgroundPosition: 'bottom left, bottom right'
            }}
        >
            {children}
        </BackgroundImage>
    );
};

IllustrationBackgroundPair.propTypes = {
    className: PropTypes.string,
    imageStack: PropTypes.array,
    children: PropTypes.node
};
