import React from 'react';
import styled from 'styled-components';
import { Box } from '../../../src/components/system';

import illustrationProcessVideoUrl from './illustration-process.mp4';

const VideoWrapper = styled.video`
    width: 100%;
    height: auto;
`;

export const IllustrationProcessVideo = () => (
    <Box width={{ _: '100%', lg: '60%' }} margin="0 auto">
        <VideoWrapper autoPlay loop muted playsInline>
            <source src={illustrationProcessVideoUrl} type="video/mp4" />
        </VideoWrapper>
    </Box>
);
