import React from 'react';
import PropTypes from 'prop-types';
import { Text } from './Text/Text';

export const Required = props => {
    if (!props.required) {
        return null;
    }

    return <Text color="signal-red.0"> *</Text>;
};

Required.propTypes = {
    required: PropTypes.bool
};
