import styled from 'styled-components';
import { space, color } from 'styled-system';
import { FLEX, LAYOUT } from '../constants';
import { theme } from '../theme';

export const Flex = styled.div`
    ${LAYOUT};
    ${space};
    ${color};
    ${FLEX};
`;

Flex.defaultProps = {
    theme,
    display: 'flex'
};

Flex.propTypes = {
    ...LAYOUT.propTypes,
    ...space.propTypes,
    ...color.propTypes,
    ...FLEX.propTypes,
    ...theme.propTypes
};
