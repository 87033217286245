import React from 'react';
import PropTypes from 'prop-types';

import { Heading5, Text } from '../system';

export const ChallengeBox = props => {
    return (
        <React.Fragment>
            {props.image}

            <Heading5 marginTop={{ xs: 3, md: 5 }}>{props.headline}</Heading5>

            <Text as="p" color="cl-dark-blue.1" fontSize={3} marginTop={3}>
                {props.description}
            </Text>
        </React.Fragment>
    );
};

ChallengeBox.propTypes = {
    image: PropTypes.node,
    headline: PropTypes.string,
    description: PropTypes.string
};
