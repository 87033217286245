import { useTeamMemberListDataWithFixedImages } from './useTeamMemberListDataWithFixedImages';

// Quick workaround since you can't pass variables to a GraphQL query.
// https://github.com/gatsbyjs/gatsby/issues/10482

export const useTeamMemberDataWithFixedImages = id => {
    const profiles = useTeamMemberListDataWithFixedImages();

    return profiles.find(profile => profile.id === id);
};
