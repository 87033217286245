import React from 'react';
import { useLocation } from '@reach/router';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, get } from '../system';
import { RoutedNavigationLink } from './RoutedNavigationLink';

const DropdownList = styled(Box)`
    display: none;
    position: absolute;
    background-color: ${get('colors.cl-dark-blue.10')};
    min-width: 100px;
`;

const DropdownButton = styled.button`
    position: relative;
    border: none;
    padding: ${get('space.2')}px 0;

    color: ${props =>
        props.active
            ? get('colors.bold-blueish.0')
            : get('colors.cl-dark-blue.0')};
    background-color: white;
    text-decoration: none;
    font-weight: ${get('fontWeights.regular')};
    font-size: ${get('fontSizes.3')}px;
    transition: color 0.15s ease;

    &:hover {
        cursor: pointer;
        color: ${get('colors.bold-blueish.0')};
    }
`;

const Dropdown = styled.div`
    &:hover {
        ${DropdownList} {
            display: block;
        }
        ${DropdownButton} {
            color: ${get('colors.bold-blueish.0')};
        }
    }
`;

export const DropdownNavigationMenu = ({ dropdownItems }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dropdownPaths = dropdownItems.map(item => item.href);
    const isActive = dropdownPaths.some(path => path === location.pathname);
    return (
        <Dropdown>
            <DropdownButton active={isActive}>
                {t('header.navigation.company')}
            </DropdownButton>
            <DropdownList as="ul" alignItems="center" boxShadow="small">
                {dropdownItems.map(item => (
                    <Box
                        as="li"
                        paddingY="12px"
                        paddingX={3}
                        display="block"
                        key={item.href}
                    >
                        <RoutedNavigationLink to={item.href}>
                            {item.text}
                        </RoutedNavigationLink>
                    </Box>
                ))}
            </DropdownList>
        </Dropdown>
    );
};

DropdownNavigationMenu.propTypes = {
    dropdownItems: PropTypes.array.isRequired
};
