import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { get } from '../get';
import { SvgFrontArrow } from '../../../icons/FrontArrow';

const LinkComponent = styled.a`
    position: relative;
    color: ${get('colors.bold-blueish.0')};
    text-decoration: none;
    font-weight: ${get('fontWeights.regular')};
    transition: all 0.15s ease;
    font-size: ${get('fontSizes.3')}px;

    &:visited {
        color: ${get('colors.bold-blueish.0')};
    }

    &:hover {
        color: ${get('colors.cl-dark-blue.0')};
        transition: all 0.15s ease;

        svg {
            transition: left 0.1s ease;
            // TODO: Check IE compatibility.
            left: calc(100% + 6px);
        }
    }

    svg {
        position: absolute;
        transition: left 0.1s ease;
        left: calc(100% + 2px);
        bottom: -3px;
    }
`;

export const Link = ({ children, ...rest }) => {
    return (
        <LinkComponent {...rest}>
            {children}
            <SvgFrontArrow />
        </LinkComponent>
    );
};

Link.propTypes = {
    children: PropTypes.node
};
